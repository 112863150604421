import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { gray, primary } from 'themes/colors';

export const StyledButton = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #cfd5e5;
  border-radius: 8px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
  background-color: #fff;
  cursor: pointer;
`;

export const StyledSideBarItem = styled(Box)`
  display: flex;
  align-items: center;
  width: 160px;
  padding: 10px 16px 10px 16px;
  border-radius: 6px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  &:hover {
    background: #f8fafe;
  }

  ${(props: { isActive: boolean }) => `
    background-color:  ${props.isActive ? primary[50] : 'none'};
  `}

  @media (max-width: 550px) {
    width: 50%;
  }
`;

export const Placeholder = styled.span`
  color: ${gray[500]};
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
`;

export const StyledCalendarsContainer = styled(Box)`
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledSidebarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 192px;

  @media (max-width: 550px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
