import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Theme, Tooltip, useMediaQuery } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
// libs
import {
  Cell,
  ColumnFiltersState,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { AxiosError } from 'axios';
import merge from 'lodash.merge';
import api from 'services/api';
import { ConfigName, CreateUpdateInterfaceConfigRequestBody } from 'services/api/types/interface-configs';
// components
import ScrollableContent from 'components/ScrollableContent';
import Loader from '../Loader';
import MobileTablePagination from './components/MobileTablePagination';
import TableFilter from './components/TableFilter';
import TablePagination from './components/TablePagination';
import TablePickColumnsModal from './components/TablePickColumnsModal';
// icons
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import { ReactComponent as SortArrowDownIcon } from 'assets/icons/sort-arrow-down.svg';
import { ReactComponent as SortArrowUpIcon } from 'assets/icons/sort-arrow-up.svg';
// styles
import { HeaderWrapper, StyledTable, TableHeader, TableHeaderCell, TableRow, TableRowCell } from './styled';
// types
import { TableProps } from './types';

import 'simplebar-react/dist/simplebar.min.css';

const TooltipIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_15530_40356)'>
      <path
        d='M6.06065 5.99967C6.21739 5.55412 6.52675 5.17841 6.93395 4.9391C7.34116 4.69978 7.81991 4.6123 8.28544 4.69215C8.75096 4.772 9.1732 5.01402 9.47737 5.37536C9.78154 5.7367 9.94802 6.19402 9.94732 6.66634C9.94732 7.99967 7.94732 8.66634 7.94732 8.66634M8.00065 11.333H8.00732M14.6673 7.99967C14.6673 11.6816 11.6826 14.6663 8.00065 14.6663C4.31875 14.6663 1.33398 11.6816 1.33398 7.99967C1.33398 4.31778 4.31875 1.33301 8.00065 1.33301C11.6826 1.33301 14.6673 4.31778 14.6673 7.99967Z'
        stroke='#CFD5E5'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_15530_40356'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const MemoizedTableRowCell = React.memo(({ cell }: { cell: Cell<any, any> }) => (
  <TableRowCell>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableRowCell>
));

MemoizedTableRowCell.displayName = 'MemoizedTableRowCell';

const Table: React.FC<TableProps> = ({
  data,
  columns,
  pageCount,
  isFetching = false,
  isPickColumnsOpen = false,
  manualPagination = true,
  pagination = { pageIndex: 0, pageSize: 10 },
  defaultHiddenColumns = {},
  defaultColumnFilters = [],
  handlePagination,
  handlePickColumnsClose,
  rowSelection = {},
  setRowSelection,
  setSelectedFaltRows,
  onFilterChange,
  sorting,
  setSorting,
  saveConfig,
  configName,
  totalRow,
}) => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { mutate } = useMutation({
    mutationFn: (data: CreateUpdateInterfaceConfigRequestBody) =>
      api.interfaceConfigs.createUpdate(configName as ConfigName, data),
  });

  const { isFetching: isConfigFetching, error } = useQuery(
    ['interfaceConfig', configName],
    () => (configName ? api.interfaceConfigs.getOne(configName) : null),
    {
      enabled: Boolean(saveConfig && configName),
      onSuccess: (data) => setColumnVisibility(data?.config.columnVisibility),
      retry: false,
    },
  );

  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(defaultColumnFilters);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(defaultHiddenColumns);

  const paginationOptions = useMemo(
    () =>
      manualPagination
        ? {
            pageCount: pageCount ?? -1,
            state: {
              pagination,
            },
            onPaginationChange: handlePagination,
            manualPagination: true,
          }
        : {
            getPaginationRowModel: getPaginationRowModel(),
          },
    [manualPagination, pageCount, pagination, handlePagination],
  );

  const handleUpdateConfig = useCallback(
    (data: VisibilityState) => mutate({ config: { columnVisibility: data } }),
    [mutate],
  );

  const table = useReactTable(
    merge(
      {
        data,
        columns,
        state: {
          columnVisibility,
          columnFilters,
          rowSelection,
          expanded,
          ...(sorting && { sorting }),
        },
        ...(setSorting && { onSortingChange: setSorting }),
        onExpandedChange: setExpanded,
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        onColumnFiltersChange: setColumnFilters,
        getRowCanExpand: () => true,
        getSubRows: (row: any) => row.subRows || [],
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getPaginationRowModel: getPaginationRowModel(),
        autoResetExpanded: false,
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
        manualSorting: Boolean(sorting),
      },
      paginationOptions,
    ),
  );

  useEffect(() => {
    setSelectedFaltRows?.(table.getSelectedRowModel().flatRows);
  }, [table, rowSelection, setSelectedFaltRows]);

  // If we don't have config on service side we send initial values
  useEffect(() => {
    if ((error as AxiosError<Error>)?.response?.status === 404) {
      mutate({ config: { columnVisibility } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, mutate]);

  if (isConfigFetching) {
    return <CircularProgress size={28} sx={{ alignSelf: 'center' }} />;
  }

  return (
    <>
      <ScrollableContent>
        {(isFetching || isConfigFetching) && <Loader sx={{ top: '52px' }} />}

        <StyledTable>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeaderCell
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ minWidth: header.getSize() !== 150 ? header.getSize() : undefined }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <HeaderWrapper
                          isSort={header.column.getCanSort()}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}

                          {header.column.columnDef.meta?.tooltipText && (
                            <Tooltip title={header.column.columnDef.meta?.tooltipText} placement='top' arrow>
                              <Box width='16px' height='16px'>
                                <TooltipIcon />
                              </Box>
                            </Tooltip>
                          )}

                          {header.column.getCanSort()
                            ? {
                                asc: <SortArrowUpIcon />,
                                desc: <SortArrowDownIcon />,
                              }[header.column.getIsSorted() as string] ?? <SortIcon />
                            : null}

                          {header.column.getCanFilter() ? (
                            <TableFilter onFilterChange={onFilterChange} column={header.column} />
                          ) : null}
                        </HeaderWrapper>
                      </>
                    )}
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </TableHeader>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} isSelected={row.getIsSelected()} isChildRow={!!row.parentId}>
                {row.getVisibleCells().map((cell) => (
                  <MemoizedTableRowCell
                    key={cell.id}
                    cell={{
                      ...cell,
                      getContext: () => ({
                        ...cell.getContext(),
                      }),
                    }}
                  />
                ))}
              </TableRow>
            ))}
            {totalRow && React.cloneElement(totalRow, { table })}
          </tbody>
        </StyledTable>
      </ScrollableContent>

      {matches ? <TablePagination table={table} /> : <MobileTablePagination table={table} />}

      <TablePickColumnsModal
        onSubmit={handleUpdateConfig}
        isOpen={isPickColumnsOpen}
        table={table}
        handleClose={handlePickColumnsClose}
      />
    </>
  );
};

export default memo(Table);
