import axios, { certisDspAxiosInstance } from 'services/api/axios';
import { Line, LineCloneRequestBody, LineRequestData } from '../types/lines';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<Line>> => {
    const response = await axios.get(queryString ? `/lines-orchestrator?${queryString}` : '/lines-orchestrator');

    return response.data;
  },
  getCore: async (id: Line['id']): Promise<Line> => {
    const response = await axios.get(`/lines/${id}/core`);

    return response.data;
  },
  getAlgorithms: async (id: Line['id']): Promise<Line> => {
    const response = await axios.get(`/lines/${id}/algorithms`);

    return response.data;
  },
  getCustomExtraCosts: async (id: Line['id']): Promise<Line> => {
    const response = await axios.get(`/lines/${id}/custom-extra-costs`);

    return response.data;
  },
  getCreatives: async (id: Line['id']): Promise<Pagination<Line>> => {
    const response = await axios.get(`/lines/${id}/creatives?$skip=0&$limit=10000`);

    return response.data;
  },
  getLimits: async (id: Line['id']): Promise<Line> => {
    const response = await axios.get(`/lines/${id}/limits`);

    return response.data;
  },
  getDevicesTargeting: async (id: Line['id']): Promise<Line> => {
    const response = await axios.get(`/lines/${id}/devices-targeting`);

    return response.data;
  },
  getExchanges: async (id: Line['id']): Promise<Line> => {
    const response = await axios.get(`/lines/${id}/exchanges?$skip=0&$limit=10000`);

    return response.data;
  },
  getGeoTargeting: async (id: Line['id']): Promise<Line> => {
    const response = await axios.get(`/lines/${id}/geo-targeting`);

    return response.data;
  },
  getPacer: async (id: Line['id']): Promise<Line> => {
    const response = await axios.get(`/lines/${id}/pacer`);

    return response.data;
  },
  getPublisherScan: async (id: Line['id']): Promise<Line> => {
    const response = await axios.get(`/lines/${id}/publisher-scan`);

    return response.data;
  },
  getSegmentsTargeting: async (id: Line['id']): Promise<Line> => {
    const response = await axios.get(`/lines/${id}/segments-targeting`);

    return response.data;
  },
  getCategories: async (id: Line['id']): Promise<Line> => {
    const response = await axios.get(`/lines/${id}/categories`);

    return response.data;
  },
  getDayparts: async (id: Line['id']): Promise<Line> => {
    const response = await axios.get(`/lines/${id}/dayparts`);

    return response.data;
  },
  create: async (data: LineRequestData): Promise<Line['id']> => {
    const response = await axios.post('/lines-orchestrator', data);

    return response.data;
  },
  update: async (id: Line['id'], data: Partial<LineRequestData>): Promise<{ id: Line['id'] }> => {
    const response = await axios.put(`/lines-orchestrator/${id}`, data);

    return response.data;
  },
  clone: async (id: Line['id'], data: LineCloneRequestBody): Promise<{ id: Line['id'] }> => {
    const response = await axios.post(`/lines-clone/${id}`, data);

    return response.data;
  },

  // Internal endpoints
  getLineI: async (id: Line['id']): Promise<Line> => {
    const response = await certisDspAxiosInstance.get(`/lines/${id}`);

    return response.data;
  },
  createI: async (data: LineRequestData): Promise<Line['id']> => {
    const response = await certisDspAxiosInstance.post('/lines', data);

    return response.data;
  },
  updateI: async (id: Line['id'], data: Partial<LineRequestData>): Promise<Line> => {
    const response = await certisDspAxiosInstance.put(`/lines/${id}`, data);

    return response.data;
  },
  cloneI: async (id: Line['id'], data: LineCloneRequestBody): Promise<Line> => {
    const response = await certisDspAxiosInstance.post(`/lines/${id}/clone`, data);

    return response.data;
  },
  getCoreI: async (id: Line['id']): Promise<Line> => {
    const response = await certisDspAxiosInstance.get(`/lines/${id}`);

    return response.data;
  },
};
