import React, { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import api from 'services/api';
import { AuthUser, AuthUserType } from 'services/api/types/auth';
import { getAuthToken } from 'services/storage/auth';
import { StoreContext } from './StoreContext';

interface AuthProviderProps {
  children: React.ReactNode;
}

export const StoreProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState({} as AuthUser);
  const [selectedAccounts, setSelectedAccounts] = useState<number[] | null>(null);
  const [token, setToken] = useState(getAuthToken());

  const [datesFilter, setDatesFilters] = useState<Record<string, Date>>({
    startsAt: moment().startOf('month').toDate(),
    expiresAt: moment().toDate(),
  });

  const { data: accountsListData, isFetching: isAccountsFetching } = useQuery(
    ['accounts'],
    () => api.accounts.getList(),
    {
      enabled: user.type === AuthUserType.Agency,
      onSuccess: (data: any) => {
        setSelectedAccounts(data.data.map((item: any) => item.id));
      },
    },
  );

  const { data: userProfileSetting } = useQuery({
    queryKey: ['user-profile-setting'],
    queryFn: () => api.userProfileSettings.getUserProfileSetting(),
    initialData: null,
    enabled: !!token,
  });

  const { data: accessControl } = useQuery({
    queryKey: ['accessControl', user.entityId, user.type],
    queryFn: () => api.accessControl.getOneExternal(user.entityId),
    initialData: null,
    enabled: Boolean(user.entityId) && user.type === AuthUserType.Account,
    retry: false,
  });

  const { data: accountDetails } = useQuery(['account', user.entityId], () => api.accounts.getOne(user.entityId), {
    enabled: Boolean(user.entityId) && user.type === 'account',
  });

  return (
    <StoreContext.Provider
      value={{
        accountDetails,
        user,
        setUser,
        setSelectedAccounts,
        accountsList: accountsListData?.data || null,
        selectedAccounts,
        token,
        setToken,
        datesFilter,
        setDatesFilters,
        userProfileSetting,
        accessControl,
        isAccountsFetching,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
