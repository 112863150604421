import { FC, memo, SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getOptionsConfig } from './helpers';
import { ListboxComponent } from './virtualizeAdapter';
import { ReactComponent as CancelCircleIcon } from 'assets/icons/cancel-circle.svg';
import { ReactComponent as CheckCircleBrokenIcon } from 'assets/icons/check-circle-broken.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/cross.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import {
  IconWrapper,
  Placeholder,
  StyledAutocompletePopper,
  StyledPopper,
  ToggleSelectButton,
  ValuesPreview,
} from './styled';
import { gray, primary } from 'themes/colors';
import { AutocompleteProps, Option, OptionValue, PopperComponentProps } from './types';

const SelectTooltipIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.0007 0.833496C4.93804 0.833496 0.833984 4.93755 0.833984 10.0002C0.833984 15.0628 4.93804 19.1668 10.0007 19.1668C15.0633 19.1668 19.1673 15.0628 19.1673 10.0002C19.1673 4.93755 15.0633 0.833496 10.0007 0.833496ZM9.08951 6.89285C9.42884 6.69342 9.82781 6.62052 10.2157 6.68706C10.6037 6.7536 10.9555 6.95529 11.209 7.2564C11.4625 7.55752 11.6012 7.93862 11.6006 8.33222L11.6006 8.33346C11.6006 8.72441 11.2964 9.13198 10.7217 9.51509C10.4598 9.68974 10.1919 9.82428 9.98616 9.9157C9.88449 9.96089 9.80093 9.99425 9.74474 10.0157C9.7198 10.0252 9.69476 10.0343 9.66958 10.0432C9.23352 10.1891 8.99797 10.6607 9.14341 11.097C9.28895 11.5336 9.76088 11.7696 10.1975 11.624L10.3381 11.5731C10.4186 11.5425 10.5303 11.4977 10.6631 11.4387C10.9261 11.3218 11.2832 11.1439 11.6462 10.9018C12.3214 10.4517 13.2669 9.60952 13.2673 8.33422C13.2684 7.5472 12.9909 6.78517 12.4841 6.18307C11.9771 5.58084 11.2734 5.17747 10.4975 5.04438C9.72164 4.9113 8.92371 5.0571 8.24504 5.45596C7.56637 5.85482 7.05076 6.481 6.78953 7.22359C6.63681 7.65775 6.86495 8.13351 7.29911 8.28624C7.73327 8.43897 8.20903 8.21082 8.36176 7.77667C8.49237 7.40537 8.75018 7.09228 9.08951 6.89285ZM10.0006 13.3335C9.54041 13.3335 9.16731 13.7066 9.16731 14.1668C9.16731 14.627 9.54041 15.0001 10.0006 15.0001H10.009C10.4692 15.0001 10.8423 14.627 10.8423 14.1668C10.8423 13.7066 10.4692 13.3335 10.009 13.3335H10.0006Z'
      fill='#647193'
    />
  </svg>
);

const CrossIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.3536 5.35355C11.5488 5.15829 11.5488 4.84171 11.3536 4.64645C11.1583 4.45118 10.8417 4.45118 10.6464 4.64645L8 7.29289L5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645C4.45118 4.84171 4.45118 5.15829 4.64645 5.35355L7.29289 8L4.64645 10.6464C4.45118 10.8417 4.45118 11.1583 4.64645 11.3536C4.84171 11.5488 5.15829 11.5488 5.35355 11.3536L8 8.70711L10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L8.70711 8L11.3536 5.35355Z'
      fill='#445171'
    />
  </svg>
);

const PopperComponent: React.FC<PopperComponentProps> = ({ disablePortal, anchorEl, open, ...other }) => (
  <StyledAutocompletePopper {...other} />
);

const NewAutocomplete: FC<AutocompleteProps> = ({
  options,
  required,
  label,
  multiple,
  placeholder,
  value,
  error,
  loading,
  helperText,
  optionValue,
  maxWidth,
  maxDisplayValueStringLength,
  disabled,
  optionsKey,
  queryData,
  queryOptions,
  onChangeHelper,
  onChange,
  onClose,
  labelHint,
  selectTooltip,
}) => {
  const displayValueBoxRef = useRef<HTMLDivElement>(null);

  const optionsConfig = useMemo(() => getOptionsConfig(optionsKey), [optionsKey]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data, isFetching } = useQuery<any>(
    [optionsKey, queryData],
    () => (optionsConfig ? optionsConfig.api(queryData) : null),
    {
      enabled: Boolean(optionsConfig) || queryOptions?.enabled,
      ...queryOptions,
    },
  );

  const fetchedOptions = useMemo(() => (data ? optionsConfig?.serializeOptions(data) : []), [data, optionsConfig]);

  const open = Boolean(anchorEl);

  const id = open ? 'autocomplete-multiple' : undefined;

  const currentOptions = optionsKey ? (fetchedOptions as Option[]) : (options as Option[]);

  const currentValue = useMemo(() => {
    if (optionValue) {
      return value as Option | Option[];
    }

    return multiple
      ? currentOptions.filter((option: Option) => (value as OptionValue[]).includes(option.value))
      : currentOptions.find((option: Option) => option.value === (value as OptionValue)) || null;
  }, [multiple, optionValue, currentOptions, value]);

  const handleChange = useCallback(
    (event: SyntheticEvent<Element, Event>, option: Option | Option[] | null) => {
      if (optionValue) {
        onChange(option);
        onChangeHelper?.(option);
        return;
      }

      if (!optionValue && !multiple) {
        const value = (option as Option)?.value ?? null;

        onChange(value);
        onChangeHelper?.(value);
        return;
      }

      if (!optionValue && multiple) {
        const value = (option as Option[]).map((item) => item.value);

        onChange(value);
        onChangeHelper?.(value);
        return;
      }
    },
    [multiple, onChange, onChangeHelper, optionValue],
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus();
    }

    onClose?.();

    setAnchorEl(null);
  }, [anchorEl, onClose]);

  const handleSelectAllClick = useCallback(() => {
    if (optionValue) {
      onChange(currentOptions);
      onChangeHelper?.(currentOptions);
    } else {
      const values = currentOptions.map((item) => item.value);
      onChange(values);
      onChangeHelper?.(values);
    }
  }, [optionValue, onChange, currentOptions, onChangeHelper]);

  const handleSelectNoneClick = useCallback(() => {
    onChange([]);
    onChangeHelper?.([]);
  }, [onChange, onChangeHelper]);

  const handleRemoveTag = useCallback(
    (tagOption: Option) => (e: any) => {
      if (!multiple) return;

      const newValue = (currentValue as Option[]).filter((tag) => tag.value !== tagOption.value);
      handleChange(e, newValue);
    },
    [currentValue, handleChange, multiple],
  );

  const displayValue = useMemo(() => {
    if (!currentValue || (multiple && (currentValue as Option[]).length === 0)) {
      return null;
    }

    if (!multiple) {
      return (currentValue as Option).label;
    }

    const selectedOptions = currentValue as Option[];
    const maxVisibleTags = 2; // Adjust this value as needed
    const visibleTags = selectedOptions.slice(0, maxVisibleTags);
    const overflowCount = selectedOptions.length - maxVisibleTags;

    return (
      <div className='tags-container'>
        {visibleTags.map((tag) => (
          <div key={tag.value} className='chip'>
            <span>{tag.label}</span>

            <div onClick={handleRemoveTag(tag)}>
              <CrossIcon />
            </div>
          </div>
        ))}
        {overflowCount > 0 && <span className='more-count'>({selectedOptions.length})</span>}
      </div>
    );
  }, [currentValue, handleRemoveTag, multiple]);

  return (
    <>
      <Box display='flex' width='100%' maxWidth={maxWidth} ref={displayValueBoxRef}>
        <FormControl fullWidth>
          {!!label && (
            <Box display='flex' alignItems='center' gap={0.5}>
              <FormLabel required={required}>{label}</FormLabel>
              {labelHint ? (
                <span>
                  <Tooltip title={labelHint}>
                    <HelpIcon width={18} height={18} />
                  </Tooltip>
                </span>
              ) : null}
            </Box>
          )}

          <ValuesPreview
            disabled={Boolean(disabled)}
            aria-describedby={id}
            onClick={disabled ? undefined : handleClick}
          >
            {displayValue ? displayValue : <Placeholder>{placeholder}</Placeholder>}
            {selectTooltip && (
              <Tooltip title={selectTooltip} arrow placement='top'>
                <Box zIndex={1300} position='absolute' top={10} right={38}>
                  <SelectTooltipIcon />
                </Box>
              </Tooltip>
            )}
            <IconWrapper open={open}>
              {loading || isFetching ? <CircularProgress size={16} /> : <ChevronDownIcon />}
            </IconWrapper>
          </ValuesPreview>

          {helperText ? <FormHelperText error={error}>{helperText}</FormHelperText> : null}
        </FormControl>
      </Box>

      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
        maxWidth={maxWidth || displayValueBoxRef.current?.getBoundingClientRect().width}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            {multiple && (
              <Stack direction='row' gap={2} mb={2}>
                <ToggleSelectButton
                  disabled={(currentValue as Option[]).length === currentOptions?.length}
                  variant='contained'
                  disableRipple
                  onClick={handleSelectAllClick}
                >
                  <CheckCircleBrokenIcon /> {'Select all'}
                </ToggleSelectButton>

                <ToggleSelectButton
                  disabled={(currentValue as Option[]).length === 0}
                  variant='contained'
                  disableRipple
                  onClick={handleSelectNoneClick}
                >
                  <CancelCircleIcon width={20} height={20} fill={primary[700]} /> {'Select none'}
                </ToggleSelectButton>
              </Stack>
            )}

            <Autocomplete
              open
              placeholder={placeholder ?? undefined}
              limitTags={1}
              disablePortal
              fullWidth
              value={currentValue}
              multiple={multiple}
              options={currentOptions}
              onChange={handleChange}
              popupIcon={<ChevronDownIcon />}
              ChipProps={{ deleteIcon: <DeleteIcon /> }}
              disableCloseOnSelect={multiple}
              getOptionLabel={(option) => String(option.label)}
              PopperComponent={PopperComponent}
              noOptionsText='No values'
              loadingText='Loading...'
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  fullWidth
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  placeholder='Search'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon width={20} height={20} fill={gray[500]} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              ListboxComponent={ListboxComponent}
              renderOption={(props, option, { selected }) => [props, option, selected, multiple] as React.ReactNode}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export default memo(NewAutocomplete);
