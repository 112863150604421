import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { AccessControl } from 'services/api/types/access-control';
import { Account } from 'services/api/types/accounts';
import { AuthUser } from 'services/api/types/auth';
import { UserProfileSettings } from 'services/api/types/user-profile-settings.ts';

export type StoreContextProps = {
  user: AuthUser;
  setUser: (values: AuthUser) => void;
  token: string | undefined;
  setToken: (token: string) => void;
  accountsList: Account[] | null;
  selectedAccounts: number[] | null;
  setSelectedAccounts: (accounts: number[] | null) => void;
  datesFilter: Record<string, Date>;
  setDatesFilters: Dispatch<SetStateAction<Record<string, Date>>>;
  userProfileSetting: UserProfileSettings | null;
  accessControl: AccessControl | null;
  accountDetails: Account | undefined;
  isAccountsFetching: boolean;
};

export const StoreContext = createContext<StoreContextProps>({
  user: {} as AuthUser,
  setUser: () => console.log('setUser'),
  token: '',
  setToken: () => console.log('setToken'),
  accountsList: null,
  selectedAccounts: null,
  setSelectedAccounts: () => console.log('setAccounts'),
  datesFilter: {},
  setDatesFilters: () => console.log('setDatesFilters'),
  userProfileSetting: null,
  accessControl: null,
  accountDetails: undefined,
  isAccountsFetching: false,
});

export const useStore = (): Readonly<StoreContextProps> => useContext(StoreContext);
