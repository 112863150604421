import React, { memo, useEffect, useState } from 'react';
// material-ui
import { Box, Stack } from '@mui/material';
// libs
import { Field, Form, Formik } from 'formik';
// components
import { ActionButton, Input } from 'components';
// icons
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
// types
import { SearchFieldProps } from './types';

interface EnhancedSearchFieldProps extends SearchFieldProps {
  submitOnChange?: boolean;
}

const InlineSearchIcon = () => (
  <svg width='20' height='20' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.66732 1.33301C3.7218 1.33301 1.33398 3.72082 1.33398 6.66634C1.33398 9.61186 3.7218 11.9997 6.66732 11.9997C7.89979 11.9997 9.03463 11.5816 9.93775 10.8796L13.5292 14.4711C13.7896 14.7314 14.2117 14.7314 14.4721 14.4711C14.7324 14.2107 14.7324 13.7886 14.4721 13.5283L10.8806 9.93677C11.5826 9.03365 12.0007 7.89882 12.0007 6.66634C12.0007 3.72082 9.61284 1.33301 6.66732 1.33301ZM2.66732 6.66634C2.66732 4.4572 4.45818 2.66634 6.66732 2.66634C8.87646 2.66634 10.6673 4.4572 10.6673 6.66634C10.6673 8.87548 8.87646 10.6663 6.66732 10.6663C4.45818 10.6663 2.66732 8.87548 2.66732 6.66634Z'
      fill='#94A0BD'
    />
  </svg>
);

const SearchField: React.FC<EnhancedSearchFieldProps> = ({ placeholder, handleSubmit, submitOnChange = false }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(() => {
    if (!submitOnChange) return;

    const handler = setTimeout(() => {
      setDebouncedValue(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, submitOnChange]);

  useEffect(() => {
    if ((submitOnChange && debouncedValue.length >= 3) || (submitOnChange && debouncedValue.length === 0)) {
      handleSubmit({ search: debouncedValue });
    }
  }, [debouncedValue, handleSubmit, submitOnChange]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const onSubmit = () => {
    handleSubmit({ search: searchTerm });
  };

  return (
    <Formik
      initialValues={{
        search: '',
      }}
      onSubmit={onSubmit}
    >
      <Form>
        <Stack direction='row' gap={1}>
          <Field
            component={Input}
            name='search'
            placeholder={placeholder}
            value={searchTerm}
            onChange={handleChange}
            sx={{ width: '320px' }}
            InputProps={{
              startAdornment: (
                <Box width='20px' height='20px'>
                  <InlineSearchIcon />
                </Box>
              ),
            }}
          />
          {!submitOnChange && (
            <ActionButton type='submit'>
              <SearchIcon />
            </ActionButton>
          )}
        </Stack>
      </Form>
    </Formik>
  );
};

export default memo(SearchField);
