export default {
  dashboard: '/',
  campaigns: '/campaigns',
  createCampaign: '/campaigns/create',
  editCampaign: '/campaigns/:id/edit',
  lines: '/lines',
  createLines: '/lines/create',
  editLines: '/lines/:id/edit',
  optimization: '/optimization',
  audiences: {
    activityAudiences: '/audiences/activity-audiences',
    activityAudienceCreate: '/audiences/activity-audiences/create',
    myAudiences: '/audiences/my-audiences',
    myAudiencesCreate: '/audiences/my-audiences/create',
    prebidAudiences: '/audiences/prebid-audiences',
    prebidAudienceCreate: '/audiences/prebid-audiences/create',
    thirdPartyAudiences: '/audiences/third-party-audiences',
    abmAudiences: '/abm/abm-audience',
    abmAudiencesCreate: '/audiences/abm-audiences/create',
  },
  analytics: {
    multidimensionalReport: '/analytics/multidimensional-report',
    standardReports: '/analytics/standard-reports',
    abmReports: '/abm/abm-reports',
    scheduledReports: '/analytics/scheduled-reports',
    deepDataReports: '/analytics/deep-data-reports',
    scheduledReportCreate: '/analytics/scheduled-reports/create',
    сohorts: '/analytics/сohorts',
    availability: '/analytics/availability',
    reportingWeb: '/analytics/reporting-web',
  },
  tracking: {
    tracking: '/tracking',
    createTracking: '/tracking/create',
    skAdCampaignMapping: '/tracking/skad-mapping',
    eventsMapping: '/tracking/events-mapping',
    eventsMappingCreate: '/tracking/events-mapping/create',
  },
  assets: {
    creatives: '/assets/creatives',
    createCreative: '/assets/creatives/create',
    editCreative: '/assets/creatives/:id/edit',
    deals: '/assets/deals',
    editDeals: '/assets/deals/:id/edit',
    abTest: '/ab-test',
    editAbTest: '/assets/ab-test/:id/edit',
    folders: '/creatives/folders',
    createFolder: '/assets/folders/create',
    editFolder: '/assets/folders/:id/edit',
    geolocation: '/assets/geolocation',
    editGeolocation: '/assets/geolocation/:id/edit',
    zipcodes: '/assets/zipcodes',
    editZipcodes: '/assets/zipcodes/:id/edit',
    regions: '/assets/regions',
    editRegions: '/assets/regions/:id/edit',
    ipRanges: '/assets/ip-ranges',
    editIpRanges: '/assets/ip-ranges/:id/edit',
    inventories: '/assets/inventories',
    editInventories: '/assets/inventories/:id/edit',
    bundles: '/assets/bundles',
    editBundles: '/assets/bundles/:id/edit',
  },
  admin: {
    users: '/admin/users',
    usersCreate: '/admin/users/create',
    accounts: '/admin/accounts',
    accountsCreate: '/admin/account/create',
    agencies: '/admin/agencies',
    bidLimiter: '/bid-limiter',
  },
  finance: {
    addFunds: '/finance/add-funds',
    billingDetails: '/finance/billing-details',
    invoices: '/finance/invoices',
    paymentMethodCreate: '/finance/payment-method/create',
    requestTopUp: '/finance/request-top-up',
  },
  featureRequest: '/feature-request',
  auth: {
    signIn: '/',
    signUp: '/sign-up',
  },
  tickets: '/tickets',
  profile: {
    notificationSettings: '/profile/notification-settings',
  },
};
