import axios, { certisDspAxiosInstance, selfserviceAxiosInstance } from 'services/api/axios';
import {
  Campaign,
  CampaignLine,
  CampaignRequestData,
  CampaignStatistics,
  CloneCampaignRequestData,
} from '../types/campaigns';
import { Dimension } from '../types/dashboard';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<Campaign>> => {
    const response = await axios.get(queryString ? `/campaigns?${queryString}` : '/campaigns');

    return response.data;
  },
  get: async (id: Campaign['id']): Promise<Campaign> => {
    const response = await axios.get(`/campaigns/${id}`);

    return response.data;
  },
  getDimensionCampaign: async (id: Campaign['id']): Promise<Campaign> => {
    const response = await axios.get(`/dashboard/dimensions/campaigns/${id}`);

    return response.data;
  },
  getLines: async (id: Campaign['id']): Promise<Pagination<CampaignLine>> => {
    const response = await axios.get(`/lines-orchestrator?$skip=0&$sort[id]=-1&$limit=10000&campaignId=${id}`);

    return response.data;
  },
  getStatistics: async (queryString?: string): Promise<CampaignStatistics[]> => {
    const response = await selfserviceAxiosInstance.get(`/line-group/statistics?${queryString}`);

    return response.data;
  },
  create: async (data: CampaignRequestData): Promise<{ id: Campaign['id'] }> => {
    const response = await axios.post('/campaigns', data);

    return response.data;
  },
  update: async (
    id: Campaign['id'],
    data: Partial<Omit<CampaignRequestData, 'accountId'>>,
  ): Promise<{ id: Campaign['id'] }> => {
    const response = await axios.patch(`/campaigns/${id}`, data);

    return response.data;
  },
  clone: async (id: Campaign['id'], data: CloneCampaignRequestData): Promise<{ id: Campaign['id'] }> => {
    const response = await axios.post(`/campaigns/${id}/clone`, data);

    return response.data;
  },

  // Internal endpoints
  getListI: async (filters?: {
    search?: string;
    accountIds?: number[];
    accountExternalIds?: number[];
    status?: string[] | null;
  }): Promise<Dimension[]> => {
    const queries: string[] = [];

    if (filters?.search) {
      queries.push(`filter[campaignOrLineName]=${filters.search}`);
    }

    if (filters?.accountExternalIds) {
      queries.push(...filters.accountExternalIds.map((item) => `filter[accountExternalIds]=${item}`));
    }

    if (filters?.accountIds) {
      queries.push(...filters.accountIds.map((item) => `filter[accountIds]=${item}`));
    }

    if (filters?.status) {
      queries.push(...filters.status.map((item) => `filter[campaignCalculatedStatuses]=${item}`));
      queries.push(...filters.status.map((item) => `filter[lineCalculatedStatuses]=${item}`));
    }

    const response = await certisDspAxiosInstance.get(
      queries.length ? `/campaigns?${queries.join('&')}` : '/campaigns',
    );

    return response.data;
  },
  getI: async (id: Campaign['id']): Promise<Campaign> => {
    const response = await certisDspAxiosInstance.get(`/campaigns/${id}`);

    return response.data;
  },
  createI: async (data: CampaignRequestData): Promise<{ id: Campaign['id'] }> => {
    const response = await certisDspAxiosInstance.post('/campaigns', data);

    return response.data;
  },
  updateI: async (id: Campaign['id'], data: Partial<Omit<CampaignRequestData, 'accountId'>>): Promise<Campaign> => {
    const response = await certisDspAxiosInstance.put(`/campaigns/${id}`, data);

    return response.data;
  },
  cloneI: async (id: Campaign['id'], data: CloneCampaignRequestData): Promise<Campaign> => {
    const response = await certisDspAxiosInstance.post(`/campaigns/${id}/clone`, data);

    return response.data;
  },
};
